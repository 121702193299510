/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

/*    name -> Tagname -> body, div, p   */
/*   .name -> classname -> <p class="foo">   */
/*   #name -> Idname -> <p id="bar">   */

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

/**
 * Reset some basic elements
 */
body, h1, h2, h3, h4, h5, h6,
p, blockquote, pre, hr,
dl, dd, ol, ul, figure {
    margin: 0;
    padding: 0;
}



/**
 * Basic styling
 */
body {
    font: $base-font-weight #{$base-font-size}/#{$base-line-height} $base-font-family;
    color: $text-color;
    background-color: $background-color;
    -webkit-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern" 1;
    -moz-font-feature-settings: "kern" 1;
    -o-font-feature-settings: "kern" 1;
    font-feature-settings: "kern" 1;
    font-kerning: normal;
}



/**
 * Set `margin-bottom` to maintain vertical rhythm
 */
h1, h2, h3, h4, h5, h6,
p, blockquote, pre,
ul, ol, dl, figure,
%vertical-rhythm {
    margin-bottom: $spacing-unit / 2;
}

h1, h2, h3, h4, h5, h6,
p, blockquote, pre,
%vertical-rhythm {
    margin-top: $spacing-unit;
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */



/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * /

/**
 * Images
 */
img {
    max-width: 100%;
    vertical-align: middle;
}



/**
 * Figures
 */
figure > img {
    display: block;
}

figcaption {
    font-size: $small-font-size;
}



/**
 * Lists
 */
ul, ol {
    margin-left: $spacing-unit;
}

li {
    > ul,
    > ol {
         margin-bottom: 0;
    }
}



/**
 * Headings
 */
h1, h2, h3, h4, h5, h6 {
    font-weight: $base-font-weight;
}



/**
 * Links
 */
a {
    color: $brand-color;
    text-decoration: none;

    &:visited {
        color: darken($brand-color, 15%);
    }

    &:hover {
        color: $text-color;
        text-decoration: underline;
    }
}



/**
 * Blockquotes
 */
blockquote {
    color: $grey-color;
    border-left: 4px solid $grey-color-light;
    padding-left: $spacing-unit / 2;
    font-size: 18px;
    letter-spacing: -1px;
    font-style: italic;

    > :last-child {
        margin-bottom: 0;
    }
}



/**
 * Code formatting
 */
pre,
code {
    font-size: 15px;
    border: 1px solid $grey-color-light;
    border-radius: 3px;
    background-color: #eef;
}

code {
    padding: 1px 5px;
}

pre {
    padding: 8px 12px;
    overflow-x: auto;

    > code {
        border: 0;
        padding-right: 0;
        padding-left: 0;
    }
}



/**
 * Wrapper
 */
.wrapper {
    max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit} * 2));
    max-width:         calc(#{$content-width} - (#{$spacing-unit} * 2));
    margin-right: auto;
    margin-left: auto;
    padding-right: $spacing-unit;
    padding-left: $spacing-unit;
    @extend %clearfix;

    @include media-query($on-laptop) {
        max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit}));
        max-width:         calc(#{$content-width} - (#{$spacing-unit}));
        padding-right: $spacing-unit / 2;
        padding-left: $spacing-unit / 2;
    }
}



/**
 * Clearfix
 */
%clearfix {

    &:after {
        content: "";
        display: table;
        clear: both;
    }
}



/**
 * Icons
 */
.icon {

    > svg {
        display: inline-block;
        width: 16px;
        height: 16px;
        vertical-align: middle;

        path {
            fill: $grey-color;
        }
    }
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * /
/* * * OWN CHANGES * * */

/**
 * Media
 */
.media-container {
	position: relative;
/*	padding-top: 30px;*/
	padding-top: 1px;
/*	padding-bottom: 56.25%;*/
	padding-bottom: 56%;
	height: 0;
	overflow: hidden;
}
.media-container iframe,  
.media-container object,  
.media-container embed,
.media-container video,
.media-container img {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	max-height: calc(90vh);
}
.media-wrapper {
	width: 600px;
	max-width: 100%;
}

div#fill {
/*    height: 100vh;*/
/*    width: 100vw;*/
/*    background: black;*/
    height: calc(100vh - 8em);
}

.empty-container {
/*	position: relative;*/
/*	padding-top: 1px;*/
/*	padding-bottom: 56%;*/
/*	height: 0;*/
/*	overflow: hidden;*/
	
	position: relative;
/*    height: 60vh;*/
    height: 90%;
/*    height: 70vh;*/
	overflow: hidden;
}
.empty-container empty,  
.empty-container object,  
.empty-container embed {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

/*    height: 60vh;*/
/*    frameborder: 0;*/
/*    scrolling: yes;*/
}

.empty {
    height: 100%;
}



/**
 * Split
 */
#split_left {
	width: 50%;
	float:left;
}
#split_right {
	margin-left: calc(50% + 0.5%);
	width: 50%;
}

.split_left {
	width: 50%;
	float:left;
}
.split_right {
	margin-left: calc(50% + 0.5%);
	width: 50%;
}


/*++++++++++++++++++++*/
#navi{
/*	border:1px solid red;*/
	width: 100%;
	width: 100%;
/*	text-align: right;*/
	float: left;
/*	background: darken($background-color, 25%);*/
	background: $background-color;
}
#navi ul{
/*	For a vertical menu instead in 'p span'.*/
/*	display: inline-block;*/
	margin: 0;
	padding: 0 2.5px 0 2.5px;
		display: inline-block;
}
	#navi ul li{

		display: inline-block;
		margin: 2.5px 0 2.5px 0;
		border:0.5px solid #567;
		padding: 1px;
		
		text-align: center;
	}
		#navi ul li a{
			padding: 10px;
			display: block;
			background: #567;
			color: white;
		}
		#navi ul li a:hover{
	        text-decoration: none;
			background: #9AB;
		}
		#navi ul li a:active{ 
		    text-decoration: none;
			background: #DEF;
			color: #000; 
		}
#navi select {
  display: none;
}
#navi-search{
			padding: 10px;
			display: block;
			background: #567;
			color: white;
}


